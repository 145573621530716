import React, { Fragment } from 'react';
import { string, bool } from 'prop-types';
import { Field } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import { Grid, Button, MenuItem } from '@material-ui/core';

// Import components
import { SelectFieldAdapter, TextFieldAdapter } from 'components/elements';

// Import utilities
import { useTranslations } from 'components/utilities';
import usePriceFieldArray from './usePriceFieldArray';

// Import styles
import useStyles from './styles';

const PriceFieldArray = ({ sectionName, submitting }) => {
	const { t } = useTranslations();

	const classes = useStyles();

	const {
		isAddButtonVisible,
		handlePushItem,
		getMenuItems,
		distances,
		prices,
	} = usePriceFieldArray(sectionName);

	return (
		<Grid container spacing={3}>
			<FieldArray name={sectionName}>
				{({ fields }) =>
					fields.map((name, index) => (
						<Fragment key={name}>
							<Grid item md={3} xs={12}>
								<Field
									fullWidth
									component={SelectFieldAdapter}
									type="select"
									label={t('offices.form.extra_services.distance_delivery')}
									name={`${name}.delivery_distance_uuid`}
									margin="dense"
									variant="outlined"
									disabled={submitting}
									required
								>
									{getMenuItems(distances, index).map(([uuid, value]) => (
										<MenuItem key={uuid} value={uuid}>
											{value}
										</MenuItem>
									))}
								</Field>
							</Grid>
							<Grid item md={3} xs={12}>
								<Field
									fullWidth
									component={SelectFieldAdapter}
									type="select"
									label={t('offices.form.extra_services.price_car_delivery')}
									name={`${name}.delivery_price_uuid`}
									margin="dense"
									variant="outlined"
									disabled={submitting}
									required
								>
									{Object.entries(prices).map(([uuid, value]) => (
										<MenuItem key={uuid} value={uuid}>
											{value}
										</MenuItem>
									))}
								</Field>
							</Grid>
							<Grid item md={3} xs={12}>
								<Field
									fullWidth
									component={TextFieldAdapter}
									type="number"
									label={t('offices.form.extra_services.window_time')}
									name={`${name}.delivery_time_window`}
									margin="dense"
									variant="outlined"
									disabled={submitting}
									required
									inputProps={{ min: 0 }}
								/>
							</Grid>
							<Grid className={classes.removeButton} item md={3} xs={12}>
								<Button
									className={classes.buttonRemove}
									variant="contained"
									onClick={() => fields.remove(index)}
									style={{ cursor: 'pointer' }}
								>
									X
								</Button>
							</Grid>
						</Fragment>
					))
				}
			</FieldArray>
			{isAddButtonVisible && (
				<Grid item md={12} xs={12}>
					<Button
						type="button"
						color="primary"
						variant="contained"
						onClick={handlePushItem}
					>
						{t('common.buttons.add')}
					</Button>
				</Grid>
			)}
		</Grid>
	);
};

PriceFieldArray.propTypes = {
	sectionName: string.isRequired,
	submitting: bool.isRequired,
};

export default PriceFieldArray;
