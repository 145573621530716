// Import helpers
import { OFFICE_TYPES } from 'helpers';

export const INITIAL_VALUES = {
	bank: {
		bank_name: '',
		iban_number: '',
		name: '',
		number: '',
		use_company_bank: false,
	},
	service_type: {
		offers_leasing_cars: false,
		b2b: false,
		digital_branch: false,
	},
	logo: { use_company_logo: false, file_path: '' },
	details: {
		name: { ar: '', en: '' },
		active: true,
		contact_number: '',
		number_prefix: '',
		person_name: '',
		public_contact_number: '',
		public_number_prefix: '',
		office_type: OFFICE_TYPES.PHYSICAL,
	},
	location: {
		address: 'Ulica II 11 Karlovac',
		city: 'Karlovac',
		country: 'sa',
		lat: 24.7135517,
		lng: 46.6752957,
		is_airport: false,
		airport: {
			terminals: [],
			locations: [],
		},
	},
	commercial_record: { number: '', file_path: '' },
	driver_license: {
		foreign_driver_licence: false,
		international_driver_licence: false,
	},
	options: {
		pickup_time: false,
		min_pickup_time: 60,
		car_delivery: false,
		car_delivery_options: [],
		driver: false,
		driver_prices: {
			daily: {
				nett_price: 0,
			},
			weekly: {
				nett_price: 0,
			},
			monthly: {
				nett_price: 0,
			},
		},
		is_delivery_drop: false,
		is_child_seat: false,
		child_seat_prices: {
			daily: {
				nett_price: 0,
			},
			weekly: {
				nett_price: 0,
			},
			monthly: {
				nett_price: 0,
			},
		},
		is_delivery_at_airport: false,
	},
	office_hours: {
		sun: {
			isOpen: true,
			open: { start: '08:00', end: '20:00' },
			isOpenSecond: false,
			isOpenThrid: false,
			openSecond: { start: '16:00', end: '22:00' },
		},
		mon: {
			isOpen: true,
			open: { start: '08:00', end: '20:00' },
			isOpenSecond: false,
			isOpenThrid: false,
			openSecond: { start: '16:00', end: '22:00' },
		},
		tue: {
			isOpen: true,
			open: { start: '08:00', end: '20:00' },
			isOpenSecond: false,
			isOpenThrid: false,
			openSecond: { start: '16:00', end: '22:00' },
		},
		wed: {
			isOpen: true,
			open: { start: '08:00', end: '20:00' },
			isOpenSecond: false,
			isOpenThrid: false,
			openSecond: { start: '16:00', end: '22:00' },
		},
		thu: {
			isOpen: true,
			open: { start: '08:00', end: '20:00' },
			isOpenSecond: false,
			isOpenThrid: false,
			openSecond: { start: '16:00', end: '22:00' },
		},
		fri: {
			isOpen: true,
			open: { start: '08:00', end: '20:00' },
			isOpenSecond: false,
			isOpenThrid: false,
			openSecond: { start: '16:00', end: '22:00' },
		},
		sat: {
			isOpen: true,
			open: { start: '08:00', end: '20:00' },
			isOpenSecond: false,
			isOpenThrid: false,
			openSecond: { start: '16:00', end: '22:00' },
		},
	},
	delivery_hours: {
		sun: {
			isOpen: true,
			open: { start: '08:00', end: '20:00' },
			isOpenSecond: false,
			openSecond: { start: '16:00', end: '22:00' },
		},
		mon: {
			isOpen: true,
			open: { start: '08:00', end: '20:00' },
			isOpenSecond: false,
			openSecond: { start: '16:00', end: '22:00' },
		},
		tue: {
			isOpen: true,
			open: { start: '08:00', end: '20:00' },
			isOpenSecond: false,
			openSecond: { start: '16:00', end: '22:00' },
		},
		wed: {
			isOpen: true,
			open: { start: '08:00', end: '20:00' },
			isOpenSecond: false,
			openSecond: { start: '16:00', end: '22:00' },
		},
		thu: {
			isOpen: true,
			open: { start: '08:00', end: '20:00' },
			isOpenSecond: false,
			openSecond: { start: '16:00', end: '22:00' },
		},
		fri: {
			isOpen: true,
			open: { start: '08:00', end: '20:00' },
			isOpenSecond: false,
			openSecond: { start: '16:00', end: '22:00' },
		},
		sat: {
			isOpen: true,
			open: { start: '08:00', end: '20:00' },
			isOpenSecond: false,
			openSecond: { start: '16:00', end: '22:00' },
		},
	},
	payments: [],
	supplier_notes: { ar: '', en: '' },
	external: { branch_id: null },
	showCompanySelect: false,
	commission: {
		start_date: null,
		end_date: null,
		percentage: '',
	},
};
