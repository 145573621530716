import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
	buttonRemove: {
		background: theme.palette.error.main,
		color: theme.palette.white,

		'&:hover': {
			background: theme.palette.error.main,
			color: theme.palette.white,
		},
	},
	removeButton: {
		marginTop: '50px',
	},
}));

export default useStyles;
