import React from 'react';
import PropTypes from 'prop-types';
import { Field, useFormState } from 'react-final-form';
import {
	Grid,
	Card,
	CardHeader,
	CardContent,
	Divider,
} from '@material-ui/core';

// Import translations
import { useTranslations } from 'components/utilities';

// Import components
import { BusinessHoursFieldAdapter } from 'components/elements';

const DeliveryHours = (props) => {
	const { className, section, ...rest } = props;

	const { t } = useTranslations();

	const { submitting } = useFormState();

	return (
		<Card {...rest} className={className}>
			<CardHeader title={t('offices.form.delivery_hours.title')} />
			<Divider />
			<CardContent>
				<Grid container spacing={3}>
					<Grid item md={12} xs={12}>
						<Field
							name={section}
							component={BusinessHoursFieldAdapter}
							disabled={submitting}
						/>
					</Grid>
				</Grid>
			</CardContent>
		</Card>
	);
};

DeliveryHours.propTypes = {
	className: PropTypes.string,
	section: PropTypes.string.isRequired,
};

export default DeliveryHours;
